<template>
	<div class="layout-box">
		<div class="m-head mobile" @click="$router.push({name:'index',query: {}})">
			<img src="../assets/logo.png">	
			<span>
				<span class="logo-ch">
					恒安康泰集团
				</span>				
				<br>
				<span class="logo-en">
					Hengan Kangtai Group
				</span>				
			</span>
				<svg t="1644315591274" @click="drawer = true" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2550" 
				width="35" height="35">
				<path d="M976 542.4H324.8c-17.6 0-32-14.4-32-32s14.4-32 32-32H976c17.6 0 32 14.4 32 32s-14.4 32-32 32zM976 833.6H324.8c-17.6 0-32-14.4-32-32s14.4-32 32-32H976c17.6 0 32 14.4 32 32s-14.4 32-32 32z" 
				fill="#43484D" p-id="2551"></path>
				<path d="M976 268.8H324.8c-17.6 0-32-14.4-32-32s14.4-32 32-32H976c17.6 0 32 14.4 32 32s-14.4 32-32 32z" 
				fill="#093C6C" p-id="2552" data-spm-anchor-id="a313x.7781069.0.i0" class="selected"></path>
				<path d="M91.2 564.8c-30.4 0-54.4-24-54.4-54.4s24-54.4 54.4-54.4 54.4 24 54.4 54.4-24 54.4-54.4 54.4z m0-64c-4.8 0-9.6 4.8-9.6 9.6s4.8 9.6 9.6 9.6 9.6-4.8 9.6-9.6-3.2-9.6-9.6-9.6zM91.2 856c-30.4 0-54.4-24-54.4-54.4s24-54.4 54.4-54.4 54.4 24 54.4 54.4-24 54.4-54.4 54.4z m0-64c-4.8 0-9.6 4.8-9.6 9.6s4.8 9.6 9.6 9.6 9.6-4.8 9.6-9.6-3.2-9.6-9.6-9.6z" 
				fill="#43484D" p-id="2553"></path>
				<path d="M91.2 291.2c-30.4 0-54.4-24-54.4-54.4s24-54.4 54.4-54.4 54.4 24 54.4 54.4-24 54.4-54.4 54.4z m0-64c-4.8 0-9.6 4.8-9.6 9.6s4.8 9.6 9.6 9.6 9.6-4.8 9.6-9.6-3.2-9.6-9.6-9.6z" 
				fill="#093C6C" p-id="2554" data-spm-anchor-id="a313x.7781069.0.i3" class="selected"></path>
				</svg>
			<div id="head-click"></div>
			<el-drawer
			direction="ltr"
			size="60%"
			:visible.sync="drawer"
			:with-header="false"
			>
			<ul>
				<li 
				v-for="(item,index) in navBar" 
				v-on:click="addClass(index)" 
				:key="index" 
				v-bind:class="{ pageNow:index==navId}"
				@click="$router.push({name:item.navUrl,query: {}}),drawer = false"				
				>
					{{item.navName}}
				</li>
				<img src="../assets/logo.png">
			</ul>			
			</el-drawer>
		</div>
		<div class="header">
			<div class="left" @click="$router.push({name:'index',query:{}})">
				<img src="../assets/logo.png" alt="恒安康泰集团">
				<div>
					<p title="恒安康泰集团">恒安康泰集团</p>
					<p>Hengan Kangtai Group</p>
				</div>
			</div>
			<ul class="center">
				<li
				  v-for="(item,index) in navBar" 
				  v-on:click="addClass(index)" 
				  :key="index" 
				  :title="item.navName"
				  v-bind:class="{pageNow:index==navId}"
				  @click="$router.push({name:item.navUrl,query: {}}),drawer = false"
				>
					{{item.navName}}
				</li>
			</ul>
			<div class="right">
				<input type="text" placeholder="请输入关键字">
				<button>搜索</button>
			</div>			
		</div>		
    </div>
</template>

<script>
export default { 
	name: '',
	data () {
		return{
			drawer: false,
			navBar:[
				{navName:'网站首页',navUrl:'index',},
				{navName:'业务板块',navUrl:'businessSector'},
				{navName:'公司简介',navUrl:'aboutUs'},
				{navName:'公司荣誉',navUrl:'ourHonor'},
				{navName:'广告展示',navUrl:'advertisement'},
				{navName:'媒体报道',navUrl:'mediaCoverage'},
				{navName:'APP下载',navUrl:'app'},
				{navName:'联系我们',navUrl:'contactUs'},
			]
        }
	},
	props: {		
        navId:{
            type: Number,
            required: true
        },
	},
	methods:{		
		addClass:function(index){
		this.current=index;
		},		
		goICP(){
			window.open( "https://beian.miit.gov.cn/#/Integrated/index", "_blank")
		},		
	},
}
</script>

<style scoped lang="less">
.pageNow{
	color: #093C6C;
	font-weight: 600;
}
// pc端样式
@media screen and (min-width:750px){	
	.mobile{
		display: none;
	}
	.layout-box{
		.header{
			width: 1200Px;
			margin: auto;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.left{
				align-items: center;
				display: flex;
				img{
					height: 55Px;
				}
				p{
					margin-left: 10Px;
					&:first-child{
						font-size: 22Px;
						font-weight: 700;
						cursor: pointer;
						color: #222;
						transition: all 0.2s;
						&:hover{
							color: #093C6C;
						}
					}
					&:last-child{
						font-size: 10Px;
						color: #5a5a5a;
					}
				}
			}
			.center{
				display: flex;
				font-size: 14Px;
				li{
					transition: all .4s;
					-webkit-transition: all .4s;
					-ms-transition: all .4s;
					-moz-transition: all .4s;
					width: 56Px;
					white-space: nowrap;
					line-height: 100Px;
					margin: 0 16Px;
					cursor: pointer;
					&:hover{
						transition: all .4s;
						-webkit-transition: all .4s;
						-ms-transition: all .4s;
						-moz-transition: all .4s;
						color: #093C6C;
						font-weight: 600;
					}
				}
			}
			.right{
				input{
					border-top: 1Px solid #DDDDDD;	
					border-left: 1Px solid #DDDDDD;	
					border-right: 0Px solid #DDDDDD;	
					border-bottom: 1Px solid #DDDDDD;	
					width: 200Px;
					height: 38Px;
					padding-left: 5Px;
				}
				button{
					border: 0px;	
					width: 60PX;
					height: 39Px;
					background-color: #093C6C;
					color: #fff;
					cursor: pointer;
					transition: all 0.2s;
					&:hover{
						background-color: #062949;
					}
				}

			}
		}
	}
}

// 移动端样式
@media screen and (max-width:750px){	
	.layout-box{
		.header{
			display: none;
		}
		.m-head{
			height: 47px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 1px;
			line-height: 15px;
			.logo-ch{
				font-size: 15px;
				font-weight: 700;
				letter-spacing: 2.9px;
			}
			.logo-en{
				letter-spacing: -.8px;
				font-size: 5px;
			}
			img{
				width: 40px;
				margin-left: 15px;
			}
			span{
				width: 260px;
				font-size: 14px;
				color: #333;
			}
			.icon{
				margin-right: 15px;
			}
			ul{
				margin-top: 7px;
				li{
					border-bottom: 1px solid #ccc;
					margin: 0 15px;
					line-height: 40px;
					font-size: 14px;
				}
				img{
					width: 100px;
					display: block;
					margin: 250px auto 0;
				}
			}
		}	
	}
}
</style>
