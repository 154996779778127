<template>
    <div id="goTop">
        <div class="scrolltop" v-show="goTopShow" @click="goTop">
        </div>
    </div>
</template>
<script>

export default ({
    data() {
        return {
            scrollTop: "",
            goTopShow: false
        };
    },
    watch: {
        scrollTop(val) {
            if (this.scrollTop > 500) {
                this.goTopShow = true;
            } else {
                this.goTopShow = false;
            }
        }
    },
    methods: {
        handleScroll() {
            this.scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            if (this.scrollTop > 500) {
                this.goTopShow = true;
            }
        },
        goTop() {
            let timer = null,
                _that = this;
            cancelAnimationFrame(timer);
            timer = requestAnimationFrame(function fn() {
                if (_that.scrollTop > 0) {
                    _that.scrollTop -= 250;
                    document.body.scrollTop = document.documentElement.scrollTop =
                        _that.scrollTop;
                    timer = requestAnimationFrame(fn);
                } else {
                    cancelAnimationFrame(timer);
                    _that.goTopShow = false;
                }
            });
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    }
})
</script>
<style lang='less'>
@media screen and (min-width:750px) {
    #goTop{
        .scrolltop{
                    position: fixed;
                    right: 20Px;
                    bottom: 150Px;
                    width: 50Px;
                    height: 50Px;
                    border: 1Px solid #2c2c2c;
                    border-radius: 5Px;
                    background:#fff url('~@/assets/img/index/top00.png') no-repeat;
                    background-position: 50%;
                    background-size: 28Px 28Px;
                    &:hover{
                    border: 1Px solid #093C6C;
                    border-radius: 5Px;
                    background: url('~@/assets/img/index/topdd.png') no-repeat;
                    background-position: 50%;
                    background-size: 28Px 28Px;
                    cursor: pointer;
                    }
                }
    }
}
@media screen and (max-width:750px){

    #goTop{
        .scrolltop{
                    position: fixed;
                    right: 20Px;
                    bottom: 150Px;
                    width: 35Px;
                    height: 35Px;
                    border: 1Px solid #2c2c2c;
                    border-radius: 5Px;
                    background:#fff url('~@/assets/img/index/top00.png') no-repeat;
                    background-position: 50%;
                    background-size: 18Px 18Px;
                    &:hover{
                    border: 1Px solid #093C6C;
                    border-radius: 5Px;
                    background: url('~@/assets/img/index/topdd.png') no-repeat;
                    background-position: 50%;
                    background-size: 18Px 18Px;
                    cursor: pointer;
                    }
                }
    }
}
</style>