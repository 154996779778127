<template>	
    <div class="footer">
        <div class="footer-content">
            <div class="left">
                <h1 @click="$router.push({name:'index',query:{}})" title="恒安康泰集团">恒安康泰集团</h1>
                <p>
                    <span>地址：北京海淀区上地三街9号金隅嘉华大厦C座707，北京海淀区上地信息路12号中关村发展大厦一幢4层A405 <br> 邮箱： support@hengankangtai.com</span>
                    <span>电话：(010) - 62965516（办公室） &emsp;18519001850（北京）&emsp; 18519007576（东部）&emsp; 13238406050（西部）</span>
                </p>
            </div>
            <div class="right">
                <p>
                    <span>服务热线:</span>	
                    <strong>4001-089-289</strong>
                </p>
                <img src="../assets/img/layout/footer_01.png" alt="二维码">
            </div>
            <p class="bottom">
                Copyright 版权所有 ©
                <span @click="$router.push({name:'index'})" title="恒安康泰集团">恒安康泰集团&nbsp;</span>
                <br class="mobile"/><span @click="goICP" title="工信部ICP/IP/信息备案号">京ICP备2020038452号-3</span>
            </p>
        </div>
    </div>
</template>

<script>
export default { 
	name: '',
	data () {
		return{
        }
	},
	props: {
	},
	methods:{		
		goICP(){
			window.open( "https://beian.miit.gov.cn/#/Integrated/index", "_blank")
		},			
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
    .mobile{
        display: none;
    }		
    .footer{
        padding: 50Px 0 30Px;
        background-color: #eee;
        color:#434343;
        .footer-content{
            width: 1200Px;
            margin: auto;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .left{
                width: 800Px;
                height: 145Px;
                position: relative;					
                h1{
                    font-size: 22Px;
                    transition: all 0.2s;
                    display: inline;
                    cursor: pointer;
                    &:hover{
                        color: #093C6C;
                    }					
                }
                p{
                    font-size: 14Px;
                    color: #5a5a5a;
                    position: absolute;
                    bottom: 15Px;
                    span{
                        line-height: 30Px;
                        display: block;
                    }
                    :first-child{
                    }
                }
            }
            .right{
                display: flex;
                align-items: flex-end;
                p{
                    text-align: right;
                    margin-right: 15Px;
                    span{
                        font-size: 14Px;
                        color: #5a5a5a;
                        
                    }
                    strong{
                        margin-top: 10Px;
                        font-size: 20Px;
                        display: block;	
                    }
                }
                img{
                    width: 120Px;
                }
            }
            .bottom{
                margin-top: 30Px;
                font-size: 13Px;
                color: #5a5a5a;
                span{
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:750px){		
    .footer{
        padding:15px 0;
        background-color: #eee;
        color:#434343;
        .footer-content{
            width: 100%;
            .left{					
                display: none;
            }
            .right{
                display: none;
            }
            .bottom{
                font-size: 13px;
                line-height: 25Px;
                text-align: center;
                color: #5a5a5a;
            }
        }
    }
}
</style>
